import React from "react";
// import DropdownMega from "./DropdownMega";
import DropdownItem from "./DropdownItem";
import navData from "../../../data/Navbar/nav-pages.json"
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

const NavbarDropdown = ({ data, collapse }) => {
  console.log(process.env.REACT_APP_PUBLIC_URL,'hi')
  return (
    <CSSTransition in={collapse} timeout={300} classNames="collapse-navbar">
      <div className="collapse navbar-collapse " id="navbar-menu">
        <ul
          className="nav navbar-nav navbar-center"
          data-in="fadeIn"
          data-out="fadeOut"
        >
          <li className="megamenu-fw" >
        <Link
        to={`${process.env.PUBLIC_URL}/`}
        className="dropdown-toggle"
        data-toggle="dropdown"
        
      >
        Home
      </Link>
          </li>
       
           <DropdownItem
                item={navData.subMenu}
                title={navData.title}
                key={navData.id}
              />
      
          <li className="megamenu-fw" >
        <Link 
        to="/blog-standard"
        className="dropdown-toggle"
        data-toggle="dropdown"
        
      >
        Blog
      </Link >
          </li>
          <li className="megamenu-fw" >
        <Link
        to={`/grid-space-3-columns`}
        className="dropdown-toggle"
        data-toggle="dropdown"
        
      >
        Portfolio
      </Link>
          </li>
          <li className="megamenu-fw" >
        <Link
        to={process.env.PUBLIC_URL}
        className="dropdown-toggle"
        data-toggle="dropdown"
        
      >
        Shop
      </Link>
          </li>



          {/* {data.map((item) =>{
            console.log(item)
           return item.megaMenu === true ? (
              <DropdownMega
                item={item.subMenu}
                columnTitle={item.colsTitle}
                title={item.title}
                key={item.id}
                itemsPerCol={item.itemsPerCol}
              />
            ) : (
              <DropdownItem
                item={item.subMenu}
                title={item.title}
                key={item.id}
              />
            )
            })} */}
        </ul>
      </div>
    </CSSTransition>
  );
};

export default NavbarDropdown;
