import React from "react";


const NavbarHeader = ({ type, fixed, width, handleCollapse, collapse }) => (
  <div className="navbar-header">
    <button
      type="button"
      className="navbar-toggle"
      data-toggle="collapse"
      data-target="#navbar-menu"
      onClick={handleCollapse}
    >
      <i
        className={
          "eicon ion-android-" + (collapse === true ? "close" : "menu")
        }
      ></i>
    </button>
    <div className="logo">
      <a href={process.env.PUBLIC_URL}>
        {!fixed && width > 1023 ? (
          <h1 
          style={{
            marginTop:'0',
            color:'white',
            fontSize:'30px'
          }}
          >
            MDigital Agency
          </h1>
        ) : !fixed && width < 1023 && type !== "dark" ? (
          <h1 
          style={{
            marginTop:'0',
            color:'black',
            fontSize:'30px'
          }}
          >
            MDigital Agency
          </h1>
        ) : (
          <h1 
          style={{
            marginTop:'0',
            color:'black',
            fontSize:'30px'
          }}
          >
            MDigital Agency
          </h1>
        )}
      </a>
    </div>
  </div>
);

export default NavbarHeader;
